/* You can add global styles to this file, and also import other style files */
@use '@angular/material' as mat;

@import "~firebaseui/dist/firebaseui.css";

@import "~bootstrap/scss/bootstrap";

/* You can add global styles to this file, and also import other style files */

@import "@angular/material/theming";

// Include non-theme styles for core.
@include mat.core();

// $primary: mat-palette($mat-indigo);
// $accent: mat-palette($mat-pink);

// Define a theme.
$primary: mat.define-palette(mat.$teal-palette, 800);
$accent: mat.define-palette(mat.$deep-orange-palette);

$theme: mat.define-light-theme($primary, $accent);

// Include all theme styles for the components.
@include mat.all-component-themes($theme);

/* Importing Bootstrap SCSS file. */
@import "~bootstrap/scss/bootstrap";

body {
  background-color: rgb(166, 185, 181);
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.page-container {
  margin: 10px;
}

// Create a 2 column layout when screen is more than 500px wide
@media (min-width: 768px) {
  .grid2wrapper {
    display: grid;
    grid-template-columns: 1fr 5fr;
    grid-gap: 10px;
  }
  .grid50percent {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;
  }
}
